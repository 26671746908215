import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit"
import {
    Row,
    Col,
    Spinner, CardTitle,
} from "reactstrap"
import {withRouter} from "react-router"
import {Slide, toast, ToastContainer} from "react-toastify";
import Select from "react-select";
import Switch from "react-switch";

const DataTableEnseignantSurveillance = props => {
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [nbSeance, setNbSeance] = useState("")
    const [nbSeanceExamen, setNbSeanceExamen] = useState("");

    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Suivant",
        prePageText: "Précédent",
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "50",
                value: 50,
            },
            {
                text: "100",
                value: 10,
            },
            {
                text: "150",
                value: 150,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "cin",
            text: "CIN ",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "name",
            text: "Nom & prénom",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "nb_heure_surv_max",
            text: "Nbr heure de surveillance maximum",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "survs",
            text: "Nbr heure de surveillé",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "nb_heures_restants",
            text: "Nbr heure de surveillance restant",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
    ]

    const {SearchBar} = Search
    // useEffect(async () => {
    //     const res = await API.post("surveillance_examen/index_etats_heures", {
    //         annee_id,semestre_id:props.semestre.value
    //     }).then(res => {
    //         setOrders(res.data.data.ens)
    //         setNbSeance(res.data.data.nb_heures_seances)
    //         setNbSeanceExamen(res.data.data.nb_heures_seances_examen)
    //     })
    //     setLoading(true)
    // }, [props.semestre])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await API.post("surveillance_examen/index_etats_heures", {
                    annee_id,
                    semestre_id: props.semestre.value,
                });
                const data = res.data.data;

                setOrders(data.ens);
                setNbSeance(data.nb_heures_seances);
                setNbSeanceExamen(data.nb_heures_seances_examen);
            } catch (error) {
                console.error("Erreur lors de la récupération des données :", error);
            } finally {
                setLoading(true);
            }
        };

        fetchData();
    }, [props.semestre]);


    useEffect(() => {

    }, [orders])

    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row style={{marginTop:"15px"}}>
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <Row>
                                            <Col style={{color: "#a13939",margin:"0px 0px 25px 0px"}} lg={6}>
                                                <h3 style={{color: "#a13939"}}>{"Nombre d'heure totale de seances : "} {nbSeance}</h3>
                                            </Col>
                                            <Col style={{color: "#a13939",margin:"0px 0px 25px 0px"}} lg={6}>
                                                <h3 style={{color: "#a13939"}}>{"Nombre d'heure totale de seances d'examen : "}  {nbSeanceExamen}</h3>
                                            </Col>
                                        </Row>


                                        <Col sm="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="bx bx-search-alt search-icon"/>
                                                </div>
                                            </div>
                                        </Col>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication/>}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            En cours ...{" "}
                        </h4>
                    </div>
                )}
            </div>



            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}


export default withRouter(DataTableEnseignantSurveillance)
DataTableEnseignantSurveillance.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
    semestre: PropTypes.object,
}
