import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit"
import {
    Row,
    Col,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    Button, CardTitle, Label, Form,
} from "reactstrap"
import {withRouter} from "react-router"
import Select from "react-select";
import {Slide, toast, ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import FileDownload from "js-file-download";
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import getDay from "date-fns/getDay"

const DataTableSeanceExamen = props => {
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [id, setId] = useState("")
    // Data Table
    const [orders, setOrders] = useState([])
    const [add, setAdd] = useState(0);
    const [edit, setEdit] = useState(0);
    const [delet, setDelet] = useState(0);
    const [cloture, setCloture] = useState(0);
    const [selectSemestre, setSelectSemestre] = useState("")
    const [niveau, setNiveau] = useState([])
    const [selectNiveau, setSelectNiveau] = useState("")
    const [specialite, setSpecialite] = useState([])
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [modalDeleteGroup, setModalDeleteGroup] = useState(false);
    const [salle, setSalle] = useState([]);
    const [selectSalle, setSelectSalle] = useState("");
    const [imp, setImp] = useState(0);
    const [excel, setExcel] = useState(0);
    const [minDate, setMinDate] = useState(new Date())
    const [maxDate, setMaxDate] = useState(new Date())
    const [date, setDate] = useState("");
    const [departement, setDepartement] = useState([]);
    const [selectDepartement, setSelectDepartement] = useState("");
    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Suivant",
        prePageText: "Précédent",
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "50",
                value: 50,
            },
            {
                text: "100",
                value: 10,
            },
            {
                text: "150",
                value: 150,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "date",
            text: "Date ",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "Jour",
            text: "Jours",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "heure_debut",
            text: "Heure",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "groupe",
            text: "Nom du groupe",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "capacite",
            text: "Capacité de groupe",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "matiere",
            text: "Matière",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "salle",
            text: "Salle",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "capacite_salle",
            text: "Capacité de salle",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "enseignant_responsable",
            text: "Enseignant responsable",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "Modification",
            isDummyField: true,
            text: "Modification rapide de salle",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                cloture !== 1 ? (
                        edit === 1 ? (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <i
                                    style={{color: "#ED6464", cursor: "pointer"}}
                                    onClick={() => toggle(row.id_first_seance_examen)}
                                    className="fas fa-edit"
                                ></i>
                            </div>
                        ) : (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <i
                                    style={{color: "#a7a3a3", cursor: "pointer"}}
                                    className="fas fa-edit"
                                ></i>
                            </div>
                        )
                    ) :
                    (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#a7a3a3", cursor: "pointer"}}
                                className="fas fa-edit"
                                title="Année clôturée"
                            ></i>
                        </div>
                    )
            ),
        },
        {
            dataField: "Exportation",
            isDummyField: true,
            text: "Exportation Chemise",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                cloture !== 1 ? (
                        imp === 1 ? (
                            <a
                                href={"https://flshs.scolarite.backcresus-institut.ovh/api/pdf_examen/chemise_enveloppe/" + row.id_first_seance_examen + "/" + 1}
                                target="_blank"
                                rel="noreferrer"
                                style={{display: "flex", justifyContent: "center"}}
                            >
                                <i
                                    style={{color: "#18ae3d", cursor: "pointer"}}
                                    className="fas fa-file-download"
                                ></i>
                            </a>
                        ) : (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <i
                                    style={{color: "#a7a3a3", cursor: "pointer"}}
                                    className="fas fa-file-download"
                                ></i>
                            </div>
                        )
                    ) :
                    (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#a7a3a3", cursor: "pointer"}}
                                className="fas fa-file-download"
                                title="Année clôturée"
                            ></i>
                        </div>
                    )
            ),
        },
        {
            dataField: "Exportation",
            isDummyField: true,
            text: "Exportation Envelope",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                cloture !== 1 ? (
                        imp === 1 ? (
                            <a
                                href={"https://flshs.scolarite.backcresus-institut.ovh/api/pdf_examen/chemise_enveloppe/" + row.id_first_seance_examen + "/" + 2}
                                target="_blank"
                                rel="noreferrer"
                                style={{display: "flex", justifyContent: "center"}}
                            >
                                <i
                                    style={{color: "#c59f03", cursor: "pointer"}}
                                    className="fas fa-file-download"
                                ></i>
                            </a>
                        ) : (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <i
                                    style={{color: "#a7a3a3", cursor: "pointer"}}
                                    className="fas fa-file-download"
                                ></i>
                            </div>
                        )
                    ) :
                    (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#a7a3a3", cursor: "pointer"}}
                                className="fas fa-file-download"
                                title="Année clôturée"
                            ></i>
                        </div>
                    )
            ),
        },
    ]
    const uploadExcel = (id) => {
        API.post('pdf_examen/chemise_enveloppe',
            {
                seance_id: id,
                type: 1
            }
            , {responseType: "blob"}
        ).then(res => {
            FileDownload(res.data, "Chemise d'examen.xlsx")
        })
    }
    const {SearchBar} = Search

    useEffect(async () => {
        setSelectNiveau("")
        setSelectSpecialite("")
        setDate("")
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Groupe d'examen")
        );
        setAdd(typeArticleSubMenu.sous_menu[0].add)
        setEdit(typeArticleSubMenu.sous_menu[0].edit)
        setDelet(typeArticleSubMenu.sous_menu[0].delete)
        setExcel(typeArticleSubMenu.sous_menu[0].exel)
        setImp(typeArticleSubMenu.sous_menu[0].imp)
        const resDepartement = await API.get("departement/select").then(resDepartement => {
            setDepartement(resDepartement.data.Departement)
            setSelectDepartement("")
        })
        const res = await API.post("seance_examen/index", {annee_id, semestre_id: props.semestre.value}).then(res => {
            setOrders(res.data.list)
        })
        const resNiveau = await API.get("niveau/select").then(resNiveau => {
            setNiveau(resNiveau.data.Niveau)
        })
        API.post("examen_parametrage/getBy_annee_semestre", {
            semestre_id: props.semestre.value, annee_id
        }).then(resAnnee => {
            setDate(new Date(resAnnee.data.params.date_debut))
            setMinDate(new Date(resAnnee.data.params.date_debut))
            setMaxDate(new Date(resAnnee.data.params.date_fin))
        })
        setLoading(true)
    }, [props.semestre])

    const toggle = id => {
        setModal(!modal)
        if (modal !== true) {
            setId(id)
            API.post("salle_examen/get_libre", {
                id: id,
                annee_id
            }).then(res => {
                setSalle(res.data.salles)
            })
        }
    }
    const toggleDeleteGroup = id => {
        if (selectSemestre === "" && selectNiveau === "" && selectSpecialite === "") {
            toast.error("⛔ Tous les champs sont obligatoire !", {
                containerId: "A",
            })
        } else {
            setModalDeleteGroup(!modalDeleteGroup)
            setId(id)
        }
    }

    const toggleDelete = async () => {

        const res = await API.post("salle_examen/update_seances", {
            seance_id: id,
            annee_id,
            salle_id: selectSalle.value,
            user_id
        }).then(res => {
            API.post("seance_examen/index", {annee_id, semestre_id: props.semestre.value}).then(res => {
                setOrders(res.data.list)
            })
            setLoading(true)
        })
    }
    const toggleDeleteGroupExamen = async () => {
        setModalDeleteGroup(false)
        const res = await API.post("groupe_examen/destroy_by_spec_niv_sem", {
            specialite_id: selectSpecialite.value,
            niveau_id: selectNiveau.value,
            semestre_id: props.semestre,
            user_id
        }).then(res => {
            const resD = API.post("groupe_examen/liste", {annee_id}).then(resD => {
                setOrders(resD.data.groupes)
            })
            setLoading(true)
        })
    }

    useEffect(() => {
        setModal(false)
    }, [orders])
    const getSpecialite = async event => {
        setSelectNiveau(event)
        const res = await API.post("group/get_list_spec", {
            niveau_id: event.value,
            semestre_id: [props.semestre],
        }).then(res => {
            setSpecialite(res.data.Groups)
        })
    }
    const search = async () => {

        const specialite_id = selectSpecialite !== "" && selectSpecialite != null ? selectSpecialite.value : 0;
        const niveau_id = selectNiveau !== "" && selectNiveau != null ? selectNiveau.value : 0;
        let convertDate = 0;
        if (date !== "" && date !== null) {
            const dateNaiss = new Date(date);
            let month = "" + (dateNaiss.getMonth() + 1);
            let day = "" + dateNaiss.getDate();
            let year = dateNaiss.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            convertDate = [year, month, day].join("-");
        }

        setOrders([])
        const res = await API.post("seance_examen/search", {
            specialite_id: specialite_id,
            niveau_id: niveau_id,
            semestre_id: props.semestre.value,
            date: convertDate,
            annee_id
        }).then(res => {
            setLoading(true)
            setOrders(res.data.list)
        })

    }
    const searchAll = async () => {
        setLoading(true)
        setSelectNiveau("")
        setSelectSpecialite("")
        setSelectSemestre("")
        const resD = API.post("seance_examen/index", {annee_id, semestre_id: props.semestre.value}).then(resD => {
            setOrders(resD.data.list)
        })
        setLoading(true)
    }

    const isWeekday = date => {
        const day = getDay(date)
        return day !== 0 && day !== 6;
    }

    const uploadExcelLicence = async () => {
        const res = await API.post('seance_examen/export',
            {
                semestre_id: props.semestre.value,
                annee_id
            },
            {responseType: "blob"}
        ).then(res => {
            FileDownload(res.data, "Tous les seances d'examn .xlsx")
        })
    }
    const uploadPDF = async () => {
        var niveau_id = selectNiveau !== "" && selectNiveau != null ? selectNiveau.value : 0
        var specialite_id = selectSpecialite !== "" && selectSpecialite != null ? selectSpecialite.value : 0
        let convertDate = 0;
        if (date !== "" && date !== null) {
            const dateNaiss = new Date(date);
            let month = "" + (dateNaiss.getMonth() + 1);
            let day = "" + dateNaiss.getDate();
            let year = dateNaiss.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            convertDate = [year, month, day].join("-");
        }
        window.open('https://flshs.scolarite.backcresus-institut.ovh/api/pdf_examen/chemise_enveloppe/'+convertDate+'/'+ niveau_id + '/' + specialite_id + '/' + 1 + '/' + annee_id + '/' + props.semestre.value, '_blank')
    }
    const uploadPDFDep = async () => {
        var departement_id = selectDepartement !== "" && selectDepartement != null ? selectDepartement.value : 0
        if(departement_id !== 0){
            window.open('https://flshs.scolarite.backcresus-institut.ovh/api/pdf_examen/impression_par_departement/' + departement_id + '/' + annee_id + '/' + props.semestre.value, '_blank')
        }else{
            toast.error("⛔ Département est obligatoire !", {
                containerId: "A",
            })
        }
    }
    const uploadPDFDate = async () => {
        let convertDate = 0;
        if (date !== "" && date !== null) {
            const dateNaiss = new Date(date);
            let month = "" + (dateNaiss.getMonth() + 1);
            let day = "" + dateNaiss.getDate();
            let year = dateNaiss.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            convertDate = [year, month, day].join("-");
        }
        if(convertDate !== 0){
            window.open('https://flshs.scolarite.backcresus-institut.ovh/api/pdf_examen/impression_par_date/' + convertDate, '_blank')
        }else{
            toast.error("⛔ Date est obligatoire !", {
                containerId: "A",
            })
        }
    }
    const uploadPDFEnveloppe = async () => {
        let convertDate = 0;
        if (date !== "" && date !== null) {
            const dateNaiss = new Date(date);
            let month = "" + (dateNaiss.getMonth() + 1);
            let day = "" + dateNaiss.getDate();
            let year = dateNaiss.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            convertDate = [year, month, day].join("-");
        }
        var niveau_id = selectNiveau !== "" && selectNiveau != null ? selectNiveau.value : 0
        var specialite_id = selectSpecialite !== "" && selectSpecialite != null ? selectSpecialite.value : 0
        window.open('https://flshs.scolarite.backcresus-institut.ovh/api/pdf_examen/chemise_enveloppe/'+convertDate+'/'+ niveau_id + '/' + specialite_id + '/' + 2 + '/' + annee_id + '/' + props.semestre.value, '_blank')

    }
    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row>
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <CardTitle style={{color: "#556ee6"}} className="h4">
                                            Critéres de Recherches
                                        </CardTitle>
                                        <Form className="mt-4">
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">Niveau</Label>
                                                        <Select
                                                            options={niveau}
                                                            isSearchable={true}
                                                            value={selectNiveau}
                                                            isClearable={true}
                                                            onChange={e => getSpecialite(e)}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">Spécialité</Label>
                                                        <Select
                                                            options={specialite}
                                                            value={selectSpecialite}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            onChange={e => setSelectSpecialite(e)}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">Date</Label>
                                                        <DatePicker
                                                            className="form-control ddate"
                                                            minDate={minDate}
                                                            maxDate={maxDate}
                                                            selected={date}
                                                            onChange={(date) => setDate(date)}
                                                            dateFormat="dd/MM/yyyy"
                                                            filterDate={isWeekday}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="basicpill-firstname-input1">Département</Label>
                                                        <Select
                                                            options={departement}
                                                            value={selectDepartement}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            onChange={e => setSelectDepartement(e)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <Col lg="12">
                                                <div className="text-center mt-4">
                                                    <button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#761C19",
                                                            borderColor: "#761C19",
                                                        }}
                                                        className="btn btn-success mb-2 me-2"
                                                        onClick={search}
                                                    >
                                                        Rechercher
                                                    </button>
                                                    <button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#761C19",
                                                            borderColor: "#761C19",
                                                        }}
                                                        className="btn btn-success mb-2 me-2"
                                                        onClick={searchAll}
                                                    >
                                                        Afficher tout
                                                    </button>

                                                    {cloture !== 1 ? (
                                                        excel === 1 ? (
                                                            <button
                                                                type="button"
                                                                style={{
                                                                    backgroundColor: "#67088d",
                                                                    borderColor: "#67088d",
                                                                }}
                                                                className="btn btn-success mb-2 me-2"
                                                                onClick={uploadExcelLicence}>
                                                                <i className="fas fa-file-excel font-size-18 align-middle me-2"></i>
                                                                {"Exportation tous les seances d'examen"}
                                                            </button>
                                                        ) : null
                                                    ) : null}
                                                    {cloture !== 1 ? (
                                                        imp === 1 ? (
                                                            <a className="col-md-3"
                                                               onClick={uploadPDF}
                                                               rel="noreferrer"
                                                            >
                                                                <button style={{
                                                                    backgroundColor: "#18ae3d",
                                                                    borderColor: "#18ae3d",
                                                                }}
                                                                        className="btn btn-primary btn btn-primary mb-2 me-2">
                                                                    <i className="fas fa-file-pdf font-size-18 align-middle me-2"></i>
                                                                    Exporter tous les chemises
                                                                </button>
                                                            </a>
                                                        ) : null
                                                    ) : null}
                                                    {cloture !== 1 ? (
                                                        imp === 1 ? (
                                                            <a className="col-md-3"
                                                               onClick={uploadPDFEnveloppe}

                                                               rel="noreferrer"
                                                            >
                                                                <button style={{
                                                                    backgroundColor: "#c59f03",
                                                                    borderColor: "#c59f03",
                                                                }}
                                                                        className="btn btn-primary btn btn-primary mb-2 me-2">
                                                                    <i className="fas fa-file-pdf font-size-18 align-middle me-2"></i>
                                                                    Exporter tous les envelopes
                                                                </button>
                                                            </a>
                                                        ) : null
                                                    ) : null}
                                                    {cloture !== 1 ? (
                                                        imp === 1 ? (
                                                            <a className="col-md-3"
                                                               onClick={uploadPDFDep}
                                                               rel="noreferrer"
                                                            >
                                                                <button style={{
                                                                    backgroundColor: "#d052de",
                                                                    borderColor: "#d052de",
                                                                }}
                                                                        className="btn btn-primary btn btn-primary mb-2 me-2">
                                                                    <i className="fas fa-file-pdf font-size-18 align-middle me-2"></i>
                                                                    Exporter les examens par département
                                                                </button>
                                                            </a>
                                                        ) : null
                                                    ) : null}
                                                    {cloture !== 1 ? (
                                                        imp === 1 ? (
                                                            <a className="col-md-3"
                                                               onClick={uploadPDFDate}
                                                               rel="noreferrer"
                                                            >
                                                                <button style={{
                                                                    backgroundColor: "#00cfff",
                                                                    borderColor: "#00cfff",
                                                                }}
                                                                        className="btn btn-primary btn btn-primary mb-2 me-2">
                                                                    <i className="fas fa-file-pdf font-size-18 align-middle me-2"></i>
                                                                    Exporter les examens par date
                                                                </button>
                                                            </a>
                                                        ) : null
                                                    ) : null}
                                                </div>
                                            </Col>

                                        </div>
                                        <Col sm="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="bx bx-search-alt search-icon"/>
                                                </div>
                                            </div>
                                        </Col>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication/>}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            responsive={true}
                                            scroll
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />

                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            En cours ...{" "}
                        </h4>
                    </div>
                )}
            </div>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggle}
                    tag="h4">
                    {"Modification rapide d'une salle d'examen pour un groupe"}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >

                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">Salle:</Label>
                                    <Select
                                        options={salle}
                                        isSearchable={true}
                                        onChange={e => setSelectSalle(e)}

                                    />
                                </div>
                            </Col>
                        </Row>


                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleDelete}
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Confirmer
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggle}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Annuler
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalDeleteGroup} toggle={toggleDeleteGroup} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggleDeleteGroup}
                    tag="h4"
                >
                    {"Suppression d'un groupe d'examen"}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p>{"Voulez - vous supprimer cette groupe d'examen ?"}</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleDeleteGroupExamen}
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggleDeleteGroup}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default withRouter(DataTableSeanceExamen)
DataTableSeanceExamen.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
    semestre: PropTypes.object,
}
